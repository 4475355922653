import React from 'react';
import { Link} from 'react-router-dom'
const Faq = () => {

    const toggleQuestion = (e) =>{
        e.currentTarget.classList.toggle('show');
        const answer = e.currentTarget.querySelector('.answer');
        const answerHeight = answer.scrollHeight;
        const isCollapsed = answer.getAttribute('data-collapsed') === 'true';
        if(isCollapsed){
            answer.setAttribute('data-collapsed',false);
            answer.style.height=answerHeight+"px";
        }else{
            answer.setAttribute('data-collapsed',true);
            answer.style.height=0;
        }
        
    }
    return ( 
        <div className="container faq-container col-md-10 page-container">
            <h3 className="page-title">
                Frequently Asked Questions
            </h3>
            <section>
                <div className="section-title">Arrival</div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What should I bring to Poland with me? </div>
                    <div className="answer" data-collapsed={true}>
                        Winters in Poland tend to be quite cold, so if you are coming from a place where that's not the case, we recommend bringing some warm clothes 😁<br/>
                        Other than that, some of our events have a more elegant dresscode, so it would be great if you would manage to bring some fitting clothes!
                        {/* <i>TO ADD: REGISTRATION @ DISCTRICT'S OFFICE</i> */}
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What should I do after arriving in Warsaw? </div>
                    <div className="answer" data-collapsed={true}>
                        First of all: pay attention to emails from our university, they will instruct you on what you have to do.
                        <br/>
                        If you live in the dormitories, you should go to the administration shortly after you arrive in order to check-in.
                        <br/>
                        Other than that - contact your mentor or anyone else from ESN if you need any help, make new friends and enjoy your time in a new environment!
                        {/* <i>TO ADD: REGISTRATION @ DISCTRICT'S OFFICE</i> */}
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >Where can I exchange currency? </div>
                    <div className="answer" data-collapsed={true}>
                        Avoid exchanging directly at airports or train stations due to higher fees. There's plenty of currency exchange points in the centre though. <br/>
                        There's also a currency exchange service close to the university campus at <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.google.com/maps/place/Jana+Ciszewskiego+15,+02-777+Warszawa/@52.154467,21.039916,17z/data=!3m1!4b1!4m5!3m4!1s0x4719329b4eb1b603:0x94942c8b41f9c119!8m2!3d52.154467!4d21.0421047" target="_blank">Jana Ciszewskiego 15</a><br/> - in the same shopping centre as E.Leclerc. <br/>
                        In order to save up on fees, you can also use services such as Revolut.
                    </div>
                </div>
            </section>
            <section>
                <div className="section-title">Dormitories</div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What are the living conditions in the dormitories? </div>
                    <div className="answer" data-collapsed={true}>
                        Dormitories you're eligible to live in are all on the campus where all the faculties are located. There are 2 and 3-person rooms. Dormitories are split
                        into segments, which are composed of 2 double and 2 triple rooms (10 people in total) - there's a shower, a toilet and 2 sinks for each segment, and a kitchen
                        for the whole floor. For more information you can look into the official website of <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="http://adiss.sggw.pl/" target="_blank">Dormitories and Canteens Administration</a><br/>
                        <br/>
                        <b>Covid-19 update:</b> double rooms have been converted to single rooms, and triples were converted to doubles.
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >How expensive are the dormitories and how can I pay?</div>
                    <div className="answer" data-collapsed={true}>
                        <b>Double rooms:</b> 400 PLN/month <br/>
                        <b>Triple rooms:</b> 350 PLN/month <br/>
                        In order to pay you have to make an online transfer to the account visible on your dormitory card / in your eHMS account.
                        If you have an account in Pekao (a Polish Bank which has a branch on campus), 
                        You can also go to the Pekao bank branch which is located on the campus with cash and the payment account number and they will help you out. <br/>
                        For each month you are supposed to pay before the 15th day of that month.
                        <br/>
                        <b>Covid-19 update:</b> double rooms are 460PLN/month, single rooms are 560PLN/month.
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >Where is the dormitory administration and when is it open?</div>
                    <div className="answer" data-collapsed={true}>
                        The administration is located on the ground floor in Adara (For Adara & Bazyliszek), Dendryt (Dendryt & Cezar), Feniks (Feniks & Eden), Grand (Grand & Hilton). <br/>
                        <b>Opening hours:</b> <br/>
                        Monday: 8.00 - 16.00 <br/>
                        Tuesday: 8.00 - 16.00 <br/>
                        Wednesday: 8.00 - 16.00 <br/>
                        Thursday: 8.00 - 20.00 <br/>
                        Friday: 8.00 - 16.00 <br/>
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >How can I do laundry in the dorms?</div>
                    <div className="answer" data-collapsed={true}>
                        The laundry rooms are located in Bazyliszek, Cezar, Eden and Hilton. If you are staying in another dormitory than these 4,
                         you can go to any of them (preferably the one next to your dorm). <br/>
                         To do laundry you have to sign up in advance at the reception, and then go there at the time of your "appointment".
                         The laundry costs 5,50 PLN. There is also a drying machine which costs 3,50 PLN per drying.


                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >Do I need to buy/bring my own kitchen utensils?</div>
                    <div className="answer" data-collapsed={true}>
                        The dormitory does not supply you with kitchen utensils, but you can borrow them from ESN office - our previous Erasmus students tend to leave it for future generations.
                        They're given away on a first-come-first-served basis, so if you come late, there might be less things to choose from.
                    </div>
                </div>
            </section>
            <section>
                <div className="section-title">University & Classes</div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What can I do if the classes from my Learning Agreement are not on the schedule?</div>
                    <div className="answer" data-collapsed={true}> 
                        Best thing to start with is to contact the teacher of the class and to ask if the class will start. Sometimes they can tell you that there are not enough students willing to take this class.
                        In that situation you can ask other Erasmus students if they're interested in the course. <br/>
                        If the course is not going to be taught, you will have to make changes to your Learning Agreement by choosing a different course from the schedule.
                        <br/>
                        You can find the list of the teachers and their emails <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.sggw.pl/en/international-cooperation_/information-for-students/sggw-studies-in-english/subjects-in-english" target="_blank">here</a><br/>
                        If you have any trouble regarding Learning Agreement, you should contact the <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.sggw.pl/en/office-of-international-cooperation" target="_blank">International Relations Office</a>
                    </div>
                </div>
                {/* <div className="question" onClick={toggleQuestion}>
                    <div className="title" >How can I order an ESN Card?</div>
                    <div className="answer" data-collapsed={true}> ...
                    </div>
                </div> */}
            </section>
            <section>
                <div className="section-title">Warsaw</div>
                {/*  Food - post Wojtka */}
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >Where can I get some good food?</div>
                    <div className="answer" data-collapsed={true}>
                    <b>Aioli</b><br/> 
                    Monday-Friday: All you have to do is buy any coffee and then you have any breakfast for just 1zł! price: 10-16zł <br/>
                    Weekends: Its backward so you pay for breakfast and you have almost any coffee for 1zl, the price is slightly higher. Breakfast is 9-12am<br/>
                    <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://goo.gl/maps/TgYb4HDVtrJ2" target="_blank">Metro Świetokrzyska</a><br/>
                    <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://maps.app.goo.gl/2viKMcZrLDpp1Jum7" target="_blank">Chmielna street</a><br/>
                    <b>Orzo</b><br/>
                    Located close to Metro Politechnika (almost the same promotions but breakfast for 5zł instead of 1zł). Breakfast 9-12am <br/>
                    <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://goo.gl/maps/Yygwpd11ncR2" target="_blank">Metro Politechnika</a><br/>
                    <b>Manekin</b><br/>
                    There are 2 Manekin, one is close to Orzo and other one is close to Metro Świętokrzyska (best crepes / pancakes)
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What are some interesting places to visit in Warsaw?</div>
                    <div className="answer" data-collapsed={true}> You should check out our <Link to="/map">Map of Warsaw</Link> with interesting places to visit recommended by ESNers and our Erasmus students from previous semesters! 
                    </div>
                </div>
            </section>
            <section>
                <div className="section-title">ESNcard</div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >What is an ESNcard and what does it offer?</div>
                    <div className="answer" data-collapsed={true}> 
                        ESNcard allows you to benefit from many discounts all over Europe, in pubs, clubs, cafes, restaurants and so on.
                        One of the most interesting offers is the <b>Ryanair</b> deal which grants you free checked-in luggage and 15% discount for 8 flights (does not apply to flights between 16th of June and 31st of August).
                        Maybe most importantly though, you are required to have an ESNcard in order to be able to join us on the great <b>ESN SGGW trips</b>! 
                        <br/><br/>
                        Learn more about ESNcard <Link to="/ESNcard">over here</Link>
                    </div>
                </div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >How can I order an ESNcard?</div>
                    <div className="answer" data-collapsed={true}> 
                        You can order your ESNcard at  <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.card.esn.pl/" target="_blank">card.esn.pl</a> and pick it up at our events or in the office of ESN SGGW during Office Hours.
                        You should then register it at <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.esncard.org/" target="_blank">esncard.org</a> 
                    </div>
                </div>
            </section>
            <section>
                <div className="section-title">Covid-19</div>
                <div className="question" onClick={toggleQuestion}>
                    <div className="title" >Where can I learn more about restrictions in Poland in regards to Covid-19?</div>
                    <div className="answer" data-collapsed={true}> 
                        You can find the most up to date information on the <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.gov.pl/web/coronavirus/temporary-limitations" target="_blank">official website of the government</a> 
                    </div>
                </div>
            </section>
            {/* <div className="disclaimer">
                Contributors: Agata P, Wojtek, Kamil
            </div> */}
        </div>
     );
}
 
export default Faq;