import React, {useState, Component} from 'react';
import {compose} from 'redux';
import {Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import {object, string, number} from 'yup';
import {Row, Col} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
//Event will have: name, description, location, isInDorm, feedBackList (array of strings), price (optional),
// rating (showing only after the event has started), startTime, endTime (optional), interestedUsers (array of Ids)
import { Editor } from '@tinymce/tinymce-react';
import {withFirestore} from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ESN_BACKGROUND from '../images/ESN_SGGW_background.png';
import marker from '../images/marker.svg';
import clock from '../images/clock.svg'
import clockBlue from '../images/clockBlue.svg';
import markerBlue from '../images/markerBlue.svg';
import clockRed from '../images/clockRed.svg';
import markerRed from '../images/markerRed.svg';
import close from '../images/close.svg';
import Loading from './Loading.js';
import NotFound from './NotFound.js';
import MessageModal from './MessageModal';
import firebase from '../firebase';


const DatePickerField = ({ name, value, onChange, clearable, minDate }) => {
    return (
        <DatePicker
            selected={(value && new Date(value)) || null}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={minDate}
            className="form-control"
            onChange={val => {
                onChange(name, val);
            }}
            isClearable={clearable}
            
        />
    );
};
const EditorField = ({name, value, onChange, initialValue})=>{
    return(
        <Editor
        initialValue={initialValue}
        init={{
        height: 300,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={val => {
            onChange(name, val);
        }}
        // onEditorChange={this.handleEditorChange}
    />
    )
}



class CropWrapper extends Component {
    constructor(props){
        super(props);
        this.cropper = React.createRef(null);
    }

    handleApplyCrop = ()=>{
        const croppedImg = this.cropper.current.cropper.getCroppedCanvas().toDataURL("image/jpeg",0.5);
        this.props.setImg('image',croppedImg);
    }
    handleReset = ()=>
        this.cropper.current.cropper.reset();
    
    render() {
      return (
          <div className="crop-wrapper-container">
            <Cropper
                ref={this.cropper}
                src={this.props.src}
                style={{height: 400, width: '100%'}}
                // Cropper.js options
                aspectRatio={16 / 9}
                guides={false}
            />
            <button className="form-control" type="button" onClick={this.handleApplyCrop}>Apply</button>
            <button className="form-control" type="button" onClick={this.handleReset}>Reset</button>
          </div>
      );
    }
  }

const Checkbox = ({ id,name, value, onChange, onBlur, checked }) => {
    return (
      <div>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value}
          onChange={val => {
                onChange(name, val.currentTarget.checked);
            }}
          onBlur={onBlur}
          className={"radio-button"}
          checked={value}
        />
      </div>
    );
  };
  const FileInput = ({id,name,value,onChange,setImage})=>{
      return (
          <input
            name={name}
            id={id}
            onChange={(val)=>{
                const file = val.target.files[0];
                //typeof returns string?
                if(typeof(file)!='object'){
                    return false;
                }
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                const reader = new FileReader();
                reader.onload = (e) => {
                    const blob = e.target.result;
                    const img = new Image();
                    img.onload=function(){
                        canvas.width=img.width;
                        canvas.height=img.height;
                        ctx.drawImage(img,0,0);
                        var newImg = new Image();
                        newImg.src=canvas.toDataURL("image/jpeg",0.5)
                        onChange(name,newImg.src).then(()=>{
                            setImage('imgOrigin',newImg.src);
                        });
                    }
                    img.src=blob;
                }
                reader.readAsDataURL(file);
            }}
            type="file"
          />
      )
  }


const CreateOrEditEvent = (props) => {
        const params = useParams();
        // const [isLoaded, setLoading] = useState(0);
        const [editEvent, setEvent] = useState(0);
        //create new event = editEvent=-1;
        const [modalVisible, setModal] = useState(false);
        // const [imageOrig, setImage] = useState(null);
        if(params.eventId && editEvent===0){ 
            props.firestore.collection(`events`).doc(params.eventId).get().then((doc)=>{
                props.firestore.collection(`tasks`).doc(params.eventId).get().then((tasksDoc)=>{
                    if(tasksDoc.data().tasks)
                        setEvent({...doc.data(),currentTasks:tasksDoc.data().tasks});
                    else
                        setEvent({...doc.data(),currentTasks:[]});
                        //redundance?
                    
                }).catch(()=>{
                    setEvent({...doc.data(),currentTasks:[]});
                });
            });
        }else if(!params.eventId && editEvent!==-1){
            setEvent(-1);
        }   
        const closeModal = () =>{
            setModal(false);
        }
        const showModal = () =>{
            setModal(true);
        }
        return ( 
            (editEvent!==0 && editEvent!==-1 && editEvent.createdBy && (editEvent.createdBy!==props.user.uid && props.user.uid!==process.env.REACT_APP_ADMIN_ID)) ? <NotFound/>: 
            <div className="container mobile-container page-container manage-event-container col-md-10">
            <MessageModal show={modalVisible} closeModal={closeModal} message={editEvent===-1 ? "Event created successfully": "Event modified successfully"} title="Success"/>
            <h3 className="page-title ">{editEvent!==-1 ? <span>Edit an event</span> : <span>Create an event</span>}</h3>
                
                {editEvent===0 ? <Loading/> : 
                <Formik
                    initialValues={{
                        name: editEvent!==-1 ? editEvent.name : '',
                        location:editEvent!==-1 ? editEvent.location : '',
                        price: editEvent!==-1 ? editEvent.price : 0,
                        startTime:editEvent!==-1 ? new Date(editEvent.startTime.seconds*1000) : new Date(),
                        endTime: (editEvent!==-1 && editEvent.endTime) ? new Date(editEvent.endTime.seconds*1000) : null,
                        description:editEvent!==-1 ? editEvent.description : '',
                        // hasDormitoryList:editEvent!==-1 ? editEvent.hasDormitoryList : '',
                        image:editEvent!==-1 ? editEvent.image : '',
                        taskName:'',
                        currentTasks: editEvent!==-1 && editEvent.currentTasks ? editEvent.currentTasks : [],
                        isPublic: true,
                        esnOnly: editEvent!==-1 && editEvent.esnOnly ? true: false,
                        zarzadOnly: editEvent!==-1 && editEvent.zarzadOnly ? true: false,
                        isTimeKnown: editEvent!== -1 && editEvent.hasOwnProperty('isTimeKnown') ? editEvent.isTimeKnown : true,
                        }}
                    enableReinitialize={true}
                    validationSchema={object({
                        name: string()
                        .required('Required')
                        .max(40,'Maximum 40 characters'),
                        location: string()
                        .required('Required'),
                        startTime: string()
                        .required('Required')
                        .typeError('Select a correct date'),
                        price: number()
                        .typeError('Must be a number')
                        .min(0,'At least 0'),
                        description: string()
                        .max(10000,'Description too long')

                    })}
                    onSubmit={(values,{setSubmitting})=>{
                        setTimeout(()=>{
                            const {startTime, endTime} = values;
                            let submitValues = values;
                            if(values.price==='')
                                submitValues.price=0;
                            let isMultiDay = false;
                            if(endTime){
                                if((startTime.getDate()!==endTime.getDate()) || ( (startTime.getDate()!==endTime.getDate()) && startTime.getMonth() !== endTime.getMonth() )){
                                    isMultiDay = true;
                                }
                            }
                            let createdBy = props.user.uid ? props.user.uid : null;
                            const createdAt = new Date();

                            submitValues = {...submitValues, isMultiDay, createdBy, createdAt}

                            //dont submit taskName - no reason to.
                            const copyTasks = submitValues.currentTasks;
                            delete submitValues.currentTasks;
                            delete submitValues.taskName;
                            delete submitValues.imgOrigin;

                            //override values if event is PRIVATE!
                            if(submitValues.esnOnly===true){
                                submitValues.isPublic=false;
                            }
                            if(submitValues.zarzadOnly===true){
                                submitValues.isPublic=false;
                                submitValues.esnOnly=false;
                            }
                            // console.log(JSON.stringify(submitValues, null,2));
                            //EDIT existing event
                            if(params.eventId)
                                props.firestore.collection(`events`).doc(params.eventId).update({...submitValues,id:params.eventId}).then((e)=>{
                                    const getId = params.eventId;
                                    const oldTasks = editEvent.currentTasks;
                                    const newTasks = copyTasks.map((task,index)=>{
                                        const taskName = Object.keys(task)[0];
                                        let oldTaskPosition = -1;
                                        //if the task already existed, transfer the list of volunteers.
                                        for (let index = 0; index < oldTasks.length ; index++){
                                            //check if name already exists.
                                            if(Object.keys(oldTasks[index])[0]===taskName){
                                                oldTaskPosition = index;
                                                break;
                                            }
                                        }
                                        //task already existed, transfer list
                                        if(oldTaskPosition!==-1) return { [taskName]:oldTasks[oldTaskPosition][taskName]}
                                        return { [taskName]:[]}
                                    });
                                    props.firestore.collection('tasks').doc(getId).set({tasks:newTasks,tasksCounter:newTasks.length,eventName:submitValues.name,eventStartTime:submitValues.startTime,zarzadOnly:submitValues.zarzadOnly}).then(()=>{
                                        firebase.analytics().logEvent('event_edit',{name:values.name});
                                        showModal();
                                        setSubmitting(false);
                                    });
                                    
                                    showModal();
                                    setSubmitting(false);
                                }).catch((err)=>{
                                    setSubmitting(false);
                                    alert('error');
                                    console.log(err);
                                });
                            else
                                props.firestore.collection(`events`).add(submitValues).then((e)=>{
                                    const newTasks = copyTasks.map(task=>{
                                        const taskName = Object.keys(task)[0];
                                        return { [taskName]:[]}
                                    });
                                    //optimization: only add document to tasks collection if there actually are some tasks.
                                    if(newTasks.length>0){
                                        props.firestore.collection('tasks').doc(e.id).set({tasks:newTasks,tasksCounter:newTasks.length,eventName:submitValues.name,eventStartTime:submitValues.startTime,zarzadOnly:submitValues.zarzadOnly}).then(()=>{
                                            showModal();
                                            setSubmitting(false);
                                            firebase.analytics().logEvent('event_create',{name:values.name});
                                        });
                                    }else{
                                        showModal();
                                        setSubmitting(false);
                                        firebase.analytics().logEvent('event_create',{name:values.name});
                                    }
                                }).catch((err)=>{
                                    setSubmitting(false);
                                    alert('error');
                                    console.log(err);
                                });;
                        },400);
                    }}
                    render = {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        isSubmitting
                    })=>{
                        const errorsNum = Object.keys(errors).length;
                        const showCropImgComponent = values.imgOrigin === undefined ? false: true;
                        return(
                            <Form className="event-form">
                            <Row className="form-group" >
                                <Col xs={12} md={6} className="form-left">
                                    <Row >
                                        <Col className="pl-0 label-container">
                                            <label htmlFor="name">Name</label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="name"/>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="pl-0  custom-pr-mobile-0">
                                            <Field id="name" className="form-control" name="name" type="text"/>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col xs={12} md={6} className="form-right">
                                    <Row >
                                        <Col className="label-container" xs={12}>
                                            <label htmlFor="dormitory">Dormitory (create list)</label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="dormitory"/>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="ml-md-0 pr-0 custom-pl-mobile-0 ">
                                            <Field id="dormitory" name="dormitory" as="select" className="form-control">
                                                <option value="none">Not in a dorm / list unnecessary</option>
                                                <option value="adara">Adara</option>
                                                <option value="bazyliszek">Bazyliszek</option>
                                                <option value="cezar">Cezar</option>
                                                <option value="dendryt">Dendryt</option>
                                                <option value="eden">Eden</option>
                                                <option value="feniks">Feniks</option>
                                                <option value="grand">Grand</option>
                                                <option value="hilton">Hilton</option>
                                            </Field>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col xs={12} md={6} className="form-left p-mob-0">
                                    <Row>
                                        <Col className="pl-0 label-container">
                                            <label htmlFor="location">Location</label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="location"/>
                                        </Col>
                                    </Row>
                                    <Row className="mr-md-0">
                                        <Field id="location" className="form-control" name="location" type="text"/>
                                    </Row>
                                </Col>
                                <Col xs={12} md={6} className="form-right">
                                    <Row>
                                        <Col className="label-container">
                                            <label htmlFor="price">Price </label>
                                        </Col>
                                        <Col className="form-error pr-md-0">
                                            <ErrorMessage name="price"/>
                                        </Col>
                                    </Row>
                                    <Row className="ml-md-0">
                                        <Field id="price" className="form-control" name="price" type="text"/>
                                    </Row>

                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col xs={12} md={6} className="form-left">
                                    <Row>
                                        <Col className=" pl-0 label-container">
                                            <label htmlFor="startTime">Start time</label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="startTime"/>
                                        </Col>
                                    </Row>
                                    <Row className="mr-md-0">
                                        <DatePickerField 
                                            id="startTime"
                                            name="startTime"
                                            onChange={setFieldValue}
                                            // minDate={new Date()}
                                            value={values.startTime}
                                            dateFormat={"MMMM d, yyyy h:mm aa"}
                                        />
                                    </Row>

                                </Col>
                                <Col xs={12} md={6} className="form-left">
                                    <Row>
                                        <Col className="label-container">
                                            <label htmlFor="endTime">End time (optional)</label>
                                        </Col>
                                        <Col className="form-error pr-md-0">
                                            <ErrorMessage name="endTime"/>
                                        </Col>
                                    </Row>
                                    <Row className="ml-md-0">
                                        <DatePickerField 
                                            id="endTime"
                                            name="endTime"
                                            onChange={setFieldValue}
                                            minDate={values.startTime}
                                            value={values.endTime}
                                            clearable={true}
                                            dateFormat={"MMMM d, yyyy h:mm aa"}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <Row>
                                    <Col className="pl-0 label-container">
                                        <label htmlFor="description">Description</label>
                                    </Col>
                                    <Col className="form-error pr-0">
                                        <ErrorMessage name="description"/>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Field id="description" className="form-control" name="description" component="textarea" type="textarea"/> */}
                                    <EditorField
                                        id="description"
                                        name="description"
                                        initialValue={values.description}
                                        value={values.description}
                                        init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onChange={setFieldValue}
                                    />
                                </Row>
                            </div>
                            <Row className="form-group">
                                <Col xs={12} md={6} className="form-left">
                                    <Row>
                                        <Col className=" pl-0 label-container image-label">
                                            <label htmlFor="image">Image </label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="image"/>
                                        </Col>
                                    </Row>
                                    <Row className="mr-md-0 image-wrapper">
                                        <FileInput name="image" id="image" className="form-control" onChange={setFieldValue} setImage={setFieldValue}/>
                                        {showCropImgComponent ? <CropWrapper setImg={setFieldValue} src={values.imgOrigin}/> : ''}
                                        
                                        {/* <Field id="image" className="form-control" name="image" type="file"/> */}
                                    </Row>
                                    <Row>
                                        <Col className=" pl-0 label-container task-label">
                                            <label htmlFor="tasks">Tasks </label>
                                        </Col>
                                        <Col className="form-error">
                                            <ErrorMessage name="tasks"/>
                                        </Col>
                                    </Row>
                                    <Row className="mr-md-0">
                                        <FieldArray
                                            name="currentTasks"
                                            render = {(arrayHelper) =>{
                                                return (
                                                <div className="tasks-container">
                                                    {values.currentTasks.map((task,index)=>{
                                                        const taskName = Object.keys(task)[0];
                                                        return(
                                                        <div key={taskName} className="single-task">
                                                            <span className="name">{taskName}</span>
                                                            <div className="icon-container" onClick={()=>{arrayHelper.remove(index)}}><img src={close} className="icon-cross" alt="remove task"/></div> 

                                                        </div>
                                                        )}
                                                    )}
                                                    <div className="add-task">
                                                        <Field type="text" className="form-control" name="taskName" id="taskName"/>
                                                        <button type="button" className="btn btn-success " onClick={()=>{arrayHelper.push({[values.taskName]:[]})}}>Add</button>
                                                    </div>

                                                </div>)
                                            }}/>

                                        {/* <Field id="image" className="form-control" name="image" type="file"/> */}
                                    </Row>

                                    <Row className="esn-only-container">
                                        <label htmlFor="isTimeKnown">Is time known?</label>
                                        <Checkbox 
                                            name="isTimeKnown"
                                            id="isTimeKnown"
                                            onChange={setFieldValue}
                                            value={values.isTimeKnown}
                                            />
                                    </Row>

                                    <Row className="esn-only-container">
                                        <label htmlFor="esnOnly">ESN Only?</label>
                                        <Checkbox 
                                            name="esnOnly"
                                            id="esnOnly"
                                            onChange={setFieldValue}
                                            value={values.esnOnly}
                                            />
                                    </Row>
                                    <Row>
                                        <div className="disclaimer">Tick this box if the event is supposed to be visible only for ESN members.</div>
                                    </Row>
                                    {props.user.isZarzad ?
                                    <Row className="esn-only-container">
                                        <label htmlFor="zarzadOnly">Zarząd & KR Only?</label>
                                        <Checkbox 
                                            name="zarzadOnly"
                                            id="zarzadOnly"
                                            onChange={setFieldValue}
                                            value={values.zarzadOnly}
                                            />
                                    </Row> : ''}
                                    {props.user.isZarzad ? <Row>
                                        <div className="disclaimer">Tick this box if the event is supposed to be visible only for the ESN Board & KR members.</div>
                                    </Row> : ''}
                                </Col>
                                <Col xs={12} md={6} className="form-right">
                                    <Row className="preview-label">Preview</Row>
                                    <Row>
                                    <div key={values.id} className="event-wrapper">
                                        <div className="link">
                                        <div className="image-wrapper">
                                            <img className="banner-img" src={values.image==='' ? ESN_BACKGROUND : values.image } alt={values.name}/>
                                        </div>
                                            <div className="description">
                                                <h5 className="name kelson-bold">{values.name}</h5>
                                                <div className={values.zarzadOnly ? 'separator zarzad-only' : values.esnOnly ? 'separator esn-only' : 'separator'}></div>
                                                <div className="date">{values.zarzadOnly ? <img className="icon" src={clockRed} alt="time"/> : values.esnOnly ? <img className="icon" src={clockBlue} alt="time"/> : <img className="icon" src={clock} alt="time"/>}Time preview disabled</div>
                                                <div className="location">{values.zarzadOnly ? <img className="icon" src={markerRed} alt="location"/> : values.esnOnly ? <img className="icon" src={markerBlue} alt="location"/> : <img className="icon" src={marker} alt="location"/>}{values.location}</div>
                                                <div className={values.zarzadOnly ? 'see-more zarzad-only' : values.esnOnly ? 'see-more esn-only' : 'see-more'}>See more</div>
                                            </div>
                                        </div>
                                    </div>
                                    </Row>
                                </Col>
                            </Row>
                            {/* <Col xs={12} md={4} className="form-left dormitory-container form-control">
                                <label htmlFor="hasDormitoryList">Create dormitory list?</label>
                                <Checkbox 
                                    name="hasDormitoryList"
                                    id="hasDormitoryList"
                                    onChange={setFieldValue}
                                    value={values.hasDormitoryList}
                                    />
                            </Col> */}
                                {errorsNum > 0 ? <Row><div className="errors">Can't submit form - fill in all the required fields and meet all the requirements.</div></Row> : ''}
                            <Row >
                                <button className="btn btn-primary mx-auto event-submit" type="submit" disabled={isSubmitting}>Submit</button>
                            </Row>
                            </Form>
                        )
                    }}>
                    
                </Formik>
            }
            </div>
         );

}
 
export default compose(withFirestore)(CreateOrEditEvent);

// class CreateOrEditEvent extends Component {
    
//     constructor(props) {
//         super(props);
//         this.state = {  }
//     }
//     render() { 

       
//     }
// }

// export default compose(withFirestore)(CreateOrEditEvent);